import { UPDATE_DESIGN } from "../actions";
import { createNewReference } from "../services/shared";

let initialState: any = {};

if ((window as any).parent.BotPenguinData) {
  initialState = {
    ...(window as any)?.parent?.BotPenguinData?.design,
    name: (window as any)?.parent?.BotPenguinData?.name
  }
} else {
  initialState = {};
}

const designReducer = ( state = initialState, action: any ) => {
  if (action.type === UPDATE_DESIGN) {
    return createNewReference({ ...state, ...action.payload });
  }
  return state;
};

export { designReducer };
