import { getSimplyBookSlots,formatTimeString } from "../SimplyBook";
import { formatTimeSlot } from "../Common";
import { getAcuitySlots } from "../Acuity";
import { STORE } from "../../../store";

const errorResponse = {
	success: false,
	availableSlots :[]
}
   /**
     * Date: 29/Jul/2024
     * Author: Rajan Grover
     * Summary: The function will create the timeslots that needs to be rendered to the end user.
     * @returns: The function will return an object containing , availableSlots array , it will contain formatted timeslots and 
     * success (boolean).
     */

export const getTimeSlots = async (
  selectedDate: string, 
  handlerType: string, 
  slots: Array<any>
): Promise<{
  success: boolean, 
  availableSlots: Array<any>
}> => {
  try {
    let timeSlots = [];

    switch (handlerType) {
      case 'simply-book': {
        const response: any = await getSimplyBookSlots(selectedDate);
        if (!response.status) {
          throw new Error('Something went wrong while fetching simply-book slots.');
        }

        const { duration, slots } = response;
        timeSlots = slots.map((slot: any) => {
          const startTime = new Date(slot.id);
          const endTime = new Date(startTime.getTime() + duration * 60000);

          return {
            id: slot.id,
            start: formatTimeString(startTime),
            end: formatTimeString(endTime)
          };
        });
        break;
      }
      case 'go-high-level':
      case 'google': {
        const allAvailableSlots = slots[0];
        const slotsForSelectedDate = allAvailableSlots[selectedDate];
        timeSlots = formatTimeSlot(slotsForSelectedDate);
        break;
      }
      case 'acuity': {
        const state: any = STORE.getState();
        const { acuityId } = state.appointmentMeta.selectedValues;
        const response: any = await getAcuitySlots(selectedDate, acuityId);
        if (!response.status) {
					throw new Error('Something went wrong while fetching acuity slots.');
        }
        timeSlots = formatTimeSlot(response.response);
        break;
      }
      default: {
        throw new Error('Invalid handler type');
      }
    }

    if (!timeSlots.length) {
			throw new Error('No available slot at the moment');
    }

    return {
      success: true,
      availableSlots: timeSlots
    };
  } catch (error) {
		return errorResponse;
  }
};
