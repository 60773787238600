import { BrowserRouter } from 'react-router-dom';

import './App.css';
import ViewHandler from './components/Layout/ViewHandler';


function App() {

  return (
    <>
      <BrowserRouter>
        <ViewHandler />
      </BrowserRouter>
    </>
  );
}

export default App;
