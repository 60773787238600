
import { ADD_ATTRIBUTE, ADD_ATTRIBUTES } from '../actions/actions';

export const attributeReducer = (state = [], action: any) => {
    if (action.type === ADD_ATTRIBUTE) {
        return [
            ...state.filter((attribute: any) => (attribute.key !== action.payload.key)),
            { ...action.payload }
        ];
    } else if (action.type === ADD_ATTRIBUTES) {        
        const attribute = state.filter((attr: { key: string }) => !((action.payload || []).some((a: {key: string}) => a.key === attr.key)));
        return [
            ...attribute,
            ...action.payload
        ];
    }
    return state;


};
