
import { AI_INITIALIZE, AI_KEY_ACTIVE, AI_REPLIES, AI_THREAD_ID, LOAD_AI_REPLIES } from './actions';

export const updateAI = (payload: any) => ({
  type: AI_REPLIES,
  payload
});

export const updateAIKey = () => ({
  type: AI_KEY_ACTIVE
});

export const updateAIThreadId = (payload: any) => ({
  type: AI_THREAD_ID,
  payload
});

export const initializeAI = (payload: any) => ({
  type: AI_INITIALIZE,
  payload
});

export const loadAiReplies = (payload: any) => ({
  type: LOAD_AI_REPLIES,
  payload
});
