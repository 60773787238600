import { httpRequest } from "./network";
import { encryptPayload } from "./saveResponse";

export const getBotById = async (botId: string, userId: string, uuid?: string) => {
    try {
        const widgetData: any = await httpRequest('GET', `website-widget/${userId}/${botId}?uuid=${uuid ?? 'null'}`)
        return widgetData.data;
    } catch (error) {
        throw new Error('Error occured while fetching widget');
    }
}

export const setUserRating = async (index: number) => {
    try {
        const widgetData: any = await httpRequest('PUT', `user/rating`, {
            binary: encryptPayload({
                uuid: localStorage.getItem('BotPenguin_User_uuid'),
                rating: index
            })
        });
        return widgetData.data;
    } catch (error) {
        throw new Error('Error occured while setting rating');
    }
}

export const isValidObjectId = (value:string) => {
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;
    return objectIdPattern.test(value);
  };