import { LOAD_FLOW, UPDATE_FLOW } from './actions';

const originalFlows = undefined;

let flows: any = sessionStorage.getItem( `BotPenguin-${ (window as any)?.parent?.BotPenguinData?._id }` );

if ( flows ) {
  try {
    flows = JSON.parse( flows ).flows || originalFlows;
  } catch ( e ) {
    flows = originalFlows;
  }
}


export const loadFlow = () => ( {
  type: LOAD_FLOW,
  payload: flows || (window as any).parent.BotPenguinData.flows
} );

export const updateFlow = (payload: any) => ({
  type: UPDATE_FLOW,
  payload
});
