import { httpRequest } from '../../../services/network';
import { encryptPayload } from '../../../services/saveResponse';
import { STORE } from '../../../store';
import { getAnsweredMessagesAndDateTime, areAllQuestionTypesPresent, getUserTimeZone } from '../Common';
import { Appointment } from '../../../interfaces/appointment';


export const getGcAvailableDatesAndSlots = async (appointmentObj: Appointment | any) => new Promise(async (resolve, reject) => {
	try {
		const { environment, flows } = STORE.getState();
		const questionArray = flows[0].questions;
		const ifEmailQueExist: boolean = areAllQuestionTypesPresent(questionArray, ['email']);
		if (!ifEmailQueExist) {
			return resolve({ status: false, message: 'Email is required to create an appointment #100004' });
		}
		const response: any = await httpRequest('POST', 'google-calendar/dates', {
			binary: encryptPayload({
				_bot: environment._id,
				_user: environment._user,
				userTimeZone: getUserTimeZone(),
				appointment: {
					availableDays: appointmentObj.availableDays,
					startTime: appointmentObj.startTime,
					endTime: appointmentObj.endTime,
					interval: appointmentObj.interval,
					futureDays: appointmentObj.futureDays,
				}
			})
		});

		return resolve({ status: true, response: response.data || response.response || response });
	} catch (error) {
		return resolve({ status: false, error });
	}
});


export const makeGcAppointment = async (answer: string) => new Promise(async (resolve, reject) => {
	try {
		let { environment, messages, appointmentMeta } = STORE.getState();
		const allSlots = appointmentMeta.slots;

		const { selectedDateTime, answeredMessages } = getAnsweredMessagesAndDateTime(allSlots, messages, answer);

		if (selectedDateTime === undefined) {
			throw new Error('No valid date and time slot found.')
		}
		const response: any = await httpRequest('POST', 'google-calendar/book', {
			binary: encryptPayload({
				_bot: environment._id,
				_user: environment._user,
				uuid: environment.uuid,
				startDate: selectedDateTime.start,
				endDate: selectedDateTime.end,
				messages: answeredMessages
			})
		});

		return resolve({ status: true, response: response.data || response.response || response });
	} catch (error) {
		return resolve({ status: false, error });
	}
});
