import { STORE } from '../../../store';
import { updateEnvironment } from '../../../actions/environment.action';
import { createPayload } from '../Common';
import { updateAppointmentMeta } from '../../../actions';
import { getDatesAndSlots, getAppointmentTypes } from './dates-types';

const errorResponse ={
  success: false,
  message:'',
  options:[]
}
   /**
     * The below handler will work only when the question is of type appointment.
     * It will handle the following cases:
     * 1 Will render dates in case of handler is one of ghl, google calendar or simply-book.
     * 2 Will render types if the handler is acuity scheduling.
     */

export const renderAppointmentQue = async (appointmentHandlerDetail: any) => {
  try {

    const state: any = STORE.getState();
    STORE.dispatch(updateEnvironment({ typing: true }));

    const { datesSent, acuityTypesSent } = state.appointmentMeta;
    const handlerType = appointmentHandlerDetail.appointmentHandler;
    const moreOption = appointmentHandlerDetail.appointment?.moreOption || 'More Option';
    let optionsPayload: any;
    let response: any;
    /**
     * For Acuity Scheduling, for very first time when the appointment question will come in flow,
     * we have to show end user the appointment types.
     */
    switch (handlerType) {
      case 'acuity':
        response = await getAppointmentTypes();
        if (response.success) {
          optionsPayload = createPayload(response.availableTypes, acuityTypesSent, moreOption, 'appointment');
          STORE.dispatch(updateAppointmentMeta({
            ...state.appointmentMeta,
            acuityTypes: response.availableTypes,
            acuityTypesSent: acuityTypesSent + optionsPayload.length - 1,
            isActive: true,
            lastQuestionSent: {
              ...appointmentHandlerDetail,
              label: appointmentHandlerDetail.label,
              options: optionsPayload
            }
          }));
        }
        break;
      /**
       * For below mentioned handler's we have to render dates when the appointment question will come,
       * as for these handler's there are no types, only dates and slot.  
       */
      case 'google':
      case 'go-high-level':
      case 'simply-book':
        response = await getDatesAndSlots(appointmentHandlerDetail);
        if (response.success) {
          optionsPayload = createPayload(response.availableDates, datesSent, moreOption, 'date');
          STORE.dispatch(updateAppointmentMeta({
            ...state.apointmentMeta,
            dates: response.availableDates,
            datesSent: datesSent + optionsPayload.length - 1,
            isActive: true,
            slots: response.availableSlots,
            lastQuestionSent: {
              ...appointmentHandlerDetail,
              label: appointmentHandlerDetail.label,
              options: optionsPayload
            }
          }));
        }
        break;
    }

    if (!response.success) {
      errorResponse.message=response.message || 'Error getting available dates or types'
      return errorResponse;
    }
    return {
      success: true,
      options: optionsPayload,
      message:'Data fetched successfully!'
    }
  } catch (error: any) {
    errorResponse.message=error.message || 'Fail to get dates or types';
    return errorResponse;
  }
}

