import React, {useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useSelector, Provider } from 'react-redux';
import { STORE } from './store';
import { updateEnvironment } from './actions/environment.action';

const root = ReactDOM.createRoot(
  document.getElementById('BotPenguin-messenger-root') as HTMLElement
);

const rtlLanguages = [
  'ar', // Arabic
  'fa', // Persian (Farsi)
  'he', // Hebrew
  'ur' // Urdu
];

/**
 * Author: Mayank
 * Date: 23 August 2024
 * Summary:
 * The RootComponent determines and applies the text direction (LTR or RTL) based on the current language setting
 * from the Redux store. It updates the global environment with the current text direction and renders the App component
 * with the appropriate text direction.
 * Description:
 * This component retrieves the current language setting from the Redux store. It uses the `useEffect` hook to determine
 * whether the language is an RTL language and sets the text direction accordingly. The component then dispatches an action
 * to update the global environment with the new text direction. The `dir` attribute of the root `div` element is set based
 * on the determined text direction, ensuring that the App component is rendered with the correct text orientation.
 */
const RootComponent = () => {
  const language = useSelector((state: any) => state?.configuration?.generalSettings?.language);
  const [direction, setDirection] = useState('ltr');
 
  useEffect(() => {
    const getDirection = () => {
      const direction = rtlLanguages.includes(language) ? 'rtl' : 'ltr';
      setDirection(direction);
      STORE.dispatch(updateEnvironment({
				direction: direction,
			}
			))
    };

    getDirection();
  }, [language]); 

  return (
    <div dir={direction}>
      <App />
    </div>
  );
};

root.render(
  // <React.StrictMode>
  <Provider store={STORE}>
      <RootComponent />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
