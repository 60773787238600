import { LOAD_TEXT_INPUT, UPDATE_TEXT_INPUT } from '../actions/actions';

const activeTabCount = parseInt(localStorage.getItem('tabCount') || '0');
let text: any = (window as any)?.parent?.BotPenguinData?.configuration?.generalSettings?.userChatRetention === 'forever' || activeTabCount
  ? localStorage.getItem(`BotPenguin-${(window as any)?.parent?.BotPenguinData?._id}`)
  : sessionStorage.getItem(`BotPenguin-${(window as any)?.parent?.BotPenguinData?._id}`);

if (text) {
  try {
    text = JSON.parse(text).text || {
      status: false,
      value: '',
      original: ''
    };
  } catch (e) {
    text = {
      status: false,
      value: '',
      original: ''
    };
  }
}

const textInputReducer = (state = text || {
  status: false,
  value: '',
  original: ''
}, action: any) => {
  switch (action.type) {
    case UPDATE_TEXT_INPUT: {
      const updatedTextInput = {
        ...state,
        ...action.payload,
        original: action.payload.original || action.payload.value || ''
      };    
      return updatedTextInput;
    }
    case LOAD_TEXT_INPUT: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export { textInputReducer };
