import { UPDATE_META } from '../actions/actions';
import { createNewReference } from '../services/shared';

export const metaReducer = ( state = {}, action: any ) => {
  if ( action.type === UPDATE_META ) {
    return createNewReference({ ...state, ...action.payload });
  } else {
    return state;
  }

};
