import { LOAD_FLOW, UPDATE_FLOW } from '../actions/actions';
import { createNewReference } from '../services/shared';

const flowReducer = ( state = [], action: any ) => {
  if ( action.type === LOAD_FLOW ) {
    return createNewReference(action.payload);
  }

  if  (action.type === UPDATE_FLOW) {
    return createNewReference(action.payload);
  }
  return state;
};

export { flowReducer };
