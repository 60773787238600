import { getBotFlows, scroll } from './shared';
import { STORE } from '../store';
import { addSystemMessage, newMessage, updateFlow, updateTextInput } from '../actions';
import { updateEnvironment } from '../actions/environment.action';
import { io } from 'socket.io-client';
import { clearRequestTimer, expireLiveChatRequest, renderNextQuestion, transferToBot } from '../utilities/LiveChat';
import { updateBotHandler } from '../actions/botHandler';
import { newMessageSound } from './notification-handler';

let typingDebounceTimer: any;
let socket: any;
let expireTransferChatTimer: any;

export const createSocketConnection = async (meta: any) => {
	try {
		const { environment, configuration, design } = STORE.getState();

		socket = io(`${process.env.REACT_APP_API_URL}`, {
			path: '/ws/chatbot', query: {
				host: window.location.hostname,
				url: window.location.href,
				...meta
			},
			reconnectionAttempts: 15,
			reconnectionDelayMax: 5000
		});

		socket.on('connected', () => {

			socket.emit('update-user-details', {
				configuration: {
					...configuration,
					triggerSettings: undefined,
					userAccessSettings: undefined,
					seoSettings: undefined,
					name: design.name
				},
				//   isRevisit: !!isRevisit
			});
			localStorage.setItem('revisited', 'bp');
			localStorage.setItem('socketDisconnected', "false");
			STORE.dispatch(updateEnvironment({
				socketDisconnected: false,
			}));
			STORE.dispatch(updateBotHandler('BOT'));
		});

		socket.on('error', console.log);

		socket.on('message', (message: any) => {

			STORE.dispatch(newMessage({
				label: message.text,
				position: 'left',
				type: 'question',
				messageBy: message.messagedBy,
				createdAt: new Date().toISOString()
			}));
			newMessageSound(configuration.generalSettings.notificationSound);
			scroll(100);
		});

		socket.on('disconnect', function () {
			STORE.dispatch(updateBotHandler('BOT'));

			STORE.dispatch(updateEnvironment({
				socketDisconnected: true,
			}));
			if (localStorage.getItem('agentId')) {
				transferToBot();
			}
		});

		socket.on('chat-request-accepted', (payload: any) => {
			localStorage.setItem('agentId', payload.agentId);
			if (payload.whatsappNumber.number && payload.whatsappNumber.prefix) {
				localStorage.setItem('bp-wsn', `+${payload.whatsappNumber.prefix}${payload.whatsappNumber.number}`);
			}
			clearRequestTimer();
			if (expireTransferChatTimer) {
				clearTimeout(expireTransferChatTimer);
			}
			STORE.dispatch(updateEnvironment({
				chatRequestRejected: false,
				disableStartButton: false,
				chatRequestAccepted: true,
				agentId: payload.agentId,
				liveChat: true,
				agentAvatar: payload.picture,
				agentName: payload.name
			}));
			STORE.dispatch(updateTextInput({
				status: true,
			}));

			STORE.dispatch(addSystemMessage({
				method: 'chat-request-accepted',
				title: configuration.generalSettings.unavailabilityMessageTitle,
				subTitle: configuration.generalSettings.unavailabilityMessage,
				agentName: payload.name
			}));
			scroll(100);
			STORE.dispatch(updateBotHandler('AGENT'));
		})

		socket.on('no-agent-found', (payload: any) => {
			if (payload.uuid === environment.uuid) {
				clearRequestTimer();
				expireLiveChatRequest(environment.uuid);

				STORE.dispatch(addSystemMessage({
					method: 'no-agent-found',
					title: configuration.generalSettings.unavailabilityMessageTitle,
					subTitle: configuration.generalSettings.unavailabilityMessage,
					titleColor: design.themeColor
				}));
				STORE.dispatch(updateEnvironment({
					liveChatExpired: true,
					chatRequestRejected: true,
					chatRequestAccepted: false,
					noAgentFound: true,
					liveChat: false,
				}));
				renderNextQuestion()
			}
		})

		// TODO: Remove
		// socket.on('chat-transfer-initiated', (payload: any) => {

		//     STORE.dispatch(addSystemMessage({
		//         method: 'initiating-live-chat',
		//         title: configuration.generalSettings.waitingMessageTitle,
		//         subTitle: configuration.generalSettings.waitingMessage,
		//         titleColor: design.themeColor
		//     }));

		//     STORE.dispatch(updateTextInput({
		//         status: false,
		//         value: ''
		//     }));
		//     expireTransferChatTimer = setTimeout(() => {
		//        
		//         expireLiveChatRequest(environment.uuid);
		//         STORE.dispatch(addSystemMessage({
		//             method: 'no-agent-found',
		//             title: configuration.generalSettings.unavailabilityMessageTitle,
		//             subTitle: configuration.generalSettings.unavailabilityMessage,
		//             titleColor: design.themeColor
		//         }));

		//     }, 120000);
		//     scroll();
		// })

		socket.on('typing-start', (payload: any) => {
			if (localStorage.getItem('agentId') && payload === environment.uuid) {
				STORE.dispatch(updateEnvironment({
					typing: true,
				}));
				scroll(100);
				setTimeout(() => {
					STORE.dispatch(updateEnvironment({
						typing: false,
					}));
				}, 3000)
			}
		})

		socket.on('typing-stop', (payload: any) => {
			if (localStorage.getItem('agentId') && payload === environment.uuid) {
				STORE.dispatch(updateEnvironment({
					typing: false,
				}));
			}
		})

		socket.on('chat-transferred', (payload: any) => {
			console.log('payload', payload.mode);
			if (payload.mode === 'BOT') {
				STORE.dispatch(updateBotHandler('BOT'));
				STORE.dispatch(updateEnvironment({
					liveChat: false,
					agentId: undefined,
					chatRequestAccepted: false,
					disableStartButton: false,
				}));
				localStorage.removeItem('agentId');
				localStorage.removeItem('bp-wsn');

				if (payload._flow) {
					getBotFlows({ type: 'redirect', next: { target: payload._flow } })
						.then((flow) => {
							STORE.dispatch(updateFlow([flow]));
							renderNextQuestion();
						})
						.catch((err) => console.log(err));
				} else {
					renderNextQuestion();
				}
			} else if (payload.mode === 'WHATSAPP') {
				STORE.dispatch(updateEnvironment({
					liveChat: false,
					agentId: undefined,
					chatRequestAccepted: false,
					disableStartButton: false,
				}));

				STORE.dispatch(updateTextInput({
					status: false,
					value: ''
				}));
				localStorage.removeItem('agentId');
				localStorage.removeItem('bp-wsn');
				scroll(100);
			}

		})
	} catch (error) {
		console.log(error, "Error in socket connection");
	}
}

export const emit = (event: any, payload: any) => {
	if (socket && socket.connected) {
		socket.emit(event, payload);
	}
};

export const updateMetaToSocket = () => {
	const { configuration, design } = STORE.getState();

	socket.emit('update-user-details', {
		configuration: {
			...configuration,
			triggerSettings: undefined,
			userAccessSettings: undefined,
			seoSettings: undefined,
			name: design.name
		},
		//   isRevisit: !!isRevisit
	});
};

export const advanceMessage = (payload: any) => {
	if (typingDebounceTimer) {
		clearTimeout(typingDebounceTimer);
	}

	typingDebounceTimer = setTimeout(() => {
		if (localStorage.getItem('agentId')) {
			socket.emit('adv-message', {
				uuid: payload.uuid,
				advanceMessage: payload.advanceMessage,
				agent: localStorage.getItem('agentId')
			})
		}
	}, 500);
}
