import moment from 'moment';
import 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { AnsweredMessage, Slots, FormatedSlots } from "../../../interfaces/appointment";
import { IQuestion } from '../../../interfaces/Question';
import { updateEnvironment } from "../../../actions/environment.action";
import { saveMessage } from "../../../services/saveResponse";
import { emit } from "../../../services/socket";
import { STORE } from "../../../store";
import { newMessage, updateAppointmentMeta } from '../../../actions';
import { findQuestions } from '../../Response';

export const getUserTimeZone = () => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone) {
      return timeZone;
    } else {
      const fallbackTimeZone = moment.tz.guess();
      if (fallbackTimeZone) {
        return fallbackTimeZone;
      }
      return 'UTC';
    }
  } catch (error) {
    return 'UTC';
  }
}
/**
 * Date: 22/Jul/2024
 * Author: Rajan Grover
 * Summary: The function creates a payload of options from a given array of items (can be dates or slots), sending 9 items(will be buttons) at a time. 
 * If there are more than 9 items, a "More Option" button is added to the payload. When the items array contain dates we have to create a dates payload,
 * so in that case the type is passed as date and timeSlot in case for slots so that we can get the respective values.
 * @param items: Array<any> - The array of items to create the payload from (Dates or slots).
 * @param itemSentCount: number - The count of items that have already been sent.
 * @param moreOption: string (optional) - The label for the "More Option" button, default is "More Option".
 * @param type: string - The type of items, e.g., 'timeSlot' or 'date'.
 * @returns Array - The payload of options with at most 9 items and an "More Option" button, if length of items array is >= 10.
 */

export const createPayload = (items: Array<any>, itemSentCount: number, moreOption: string = "More Option", type: string) => {
  const slicedOptions = items.slice(itemSentCount, itemSentCount + 9);

  const optionsPayload = slicedOptions.map((item) => {
    let value;
    if (type === 'timeSlot') {
      value = `${item.start}-${item.end}`;
    } else if (type === 'date') {
      value = item;
    } else if (type === 'appointment') {
      value = item.name;
    }

    return {
      id: uuid(),
      type: 'button',
      value: value,
      next: { type: 'button', target: 'slot-booking' }
    };
  });

  if (items.length > itemSentCount + 9) {
    optionsPayload.push({
      id: uuid(),
      type: 'button',
      value: moreOption,
      next: { type: 'appointment', target: 'more-appointment-options' }
    });
  }

  return optionsPayload;
};

/**
 * Date: 18/Jul/2024
 * Author: Rajan Grover
 * Summary: The function checks that the questions with provided types exist or not in all questions array,
 * that is it won't track the position of required type question but will check for only that case where the question with,
 * required type is present in the whole flow.
 * @param questions: Array of all questions
 * @param types:['email','phone','name']
 * @returns boolean value
 */

export const areAllQuestionTypesPresent = (questions: Array<any>, types: Array<string>): boolean => {
  let typeFound: any = {};
  types.forEach(type => {
    typeFound[type] = false;
  });

  questions.forEach(question => {
    if (typeFound.hasOwnProperty(question.type)) {
      typeFound[question.type] = true;
    }
  });

  return types.every(type => typeFound[type]);
};

/**
 * Date: 29/Jul/2024
 * Author: Rajan Grover
 * Summary:Checks if any question in the provided array has a type matching one of the specified types.
 * @param questions: Array of all questions
 * @param types:['email','phone','name']
 * @returns boolean value
 * NOTE: As of now only used for GHL, In GHL we can either send phone number or email or both, but one key is must.
 */
export const isAnyTypeFoundInQuestions = (questions: Array<any>, types: Array<string>): boolean => {
  let typeFound = false;

  questions.forEach((question: any) => {
    if (types.includes(question.type)) {
      typeFound = true;
    }
  });

  return typeFound;
};

/**
 * Date: 18/Jul/2024
 * Author: Rajan Grover
 * Summary: The function take all messages and all slots for selected date and filter out answered messages,
 * from all messages and selected slot from all slots.
 * @param allSlots: Array of timeslots for the selected date. 
 * @param messages: Array of user-bot conversation.
 * @param answer: String selected time slot.
 * @returns an object containing selected date,slot and answered messages array 
 */

export const getAnsweredMessagesAndDateTime = (
  allSlots: Array<FormatedSlots>,
  messages: Array<any>,
  answer: string
): {
  answeredMessages: Array<AnsweredMessage> | Array<any>;
  selectedDateTime: Slots | undefined;
} => {
  let selectedDateTime: Slots | undefined;

  if (allSlots && allSlots.length > 0) {
    for (const slotObj of allSlots) {
      if (slotObj[answer]) {
        selectedDateTime = {
          start: slotObj[answer].start,
          end: slotObj[answer].end
        };
        break;
      }
    }
  }

  const answeredMessages = messages.filter(message => message?.questionId);

  return {
    answeredMessages,
    selectedDateTime
  };
};

export const handleEmitMessage = (messageText: string, newMessageType: string, saveMessageType: string, options: Array<any>, messageBy = 'bot') => {
  const messageUuid = uuid();
  emit('message', {
    text: messageText,
    messageBy
  });
  STORE.dispatch(newMessage({
    label: messageText,
    position: 'left',
    type: newMessageType,
    mid: messageUuid,
    options
  }));
  saveMessage({
    type: saveMessageType,
    text: messageText,
    messagedBy: messageBy,
    mid: messageUuid
  }).then().catch();
  STORE.dispatch(updateEnvironment({ typing: false }));
  return;
};

export const formatTimeSlot = (slots: Array<Slots>): Array<FormatedSlots> => {
  return slots.map((slot: { start: string, end: string }) => {
    const startTime = slot.start.split("T")[1];
    const [startHour, startMinute] = startTime.split(":");
    const formattedStartTime = `${startHour}:${startMinute}`;

    const endTime = slot.end.split("T")[1];
    const [endHour, endMinute] = endTime.split(":");
    const formattedEndTime = `${endHour}:${endMinute}`;

    const timeKey = `${formattedStartTime}-${formattedEndTime}`;

    return {
      [timeKey]: slot,
      start: formattedStartTime,
      end: formattedEndTime,
    };
  });
};

export const handleIsMore = (type: 'date' | 'timeSlot' | 'appointment', message: string, moreOptions: string) => {
  try {
    const state: any = STORE.getState();
    const { dates, slots, slotsSent, datesSent, acuityTypes, acuityTypesSent } = state.appointmentMeta;

    const data = type === 'appointment' ? acuityTypes : type === 'date' ? dates : slots;
    const count = type === 'appointment' ? acuityTypesSent : type === 'date' ? datesSent : slotsSent;
    const countKey = type === 'appointment' ? 'acuityTypesSent' : type === 'date' ? 'datesSent' : 'slotsSent';

    const optionsPayload = createPayload(data, count, moreOptions, type);

    STORE.dispatch(updateAppointmentMeta({
      ...state.appointmentMeta, [countKey]: count + optionsPayload.length - 1
    }));
    return handleEmitMessage(message, 'button', 'button', optionsPayload);

  } catch (error) {
    // This case will not happen likely
    return handleEmitMessage('Something went wrong', 'STATEMENT', 'message', []);
  }

}

export const getNextQuestion = async (
  flowQuestions: Array<any> = [],
  activeQuestion: IQuestion
) => {
  let nextQuestion: Array<IQuestion> = [];
  const activeQueIndex: number = flowQuestions.findIndex(
    (que: IQuestion) => que.id === activeQuestion.id
  );

  if (activeQuestion.next.target) {
    const targetQuestion = flowQuestions.find(
      (que: IQuestion) => que.id === activeQuestion.next.target
    );
    if (targetQuestion) {
      nextQuestion.push(targetQuestion);
    } else {
      nextQuestion = flowQuestions.slice(
        activeQueIndex + 1,
        activeQueIndex + 2
      );
    }
  } else {
    nextQuestion = flowQuestions.slice(activeQueIndex + 1, activeQueIndex + 2);
  }

  if (nextQuestion.length) {
    if (
      nextQuestion[0].type === 'api' ||
      nextQuestion[0].type === 'statement'
    ) {
      const apiQueIndex = flowQuestions.findIndex(
        (que: IQuestion) => que.id === nextQuestion[0].id
      );
      nextQuestion = await findQuestions(flowQuestions, apiQueIndex);
    }
    return nextQuestion;
  } else {
    return [];
  }
};

