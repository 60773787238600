import {
  ADD_KNOWLEDGE_BASE,
  GET_KNOWLEDGE_BASE,
  SET_KNOWLEDGE_BASE_SUBPAGE,
  RESET_KNOWLEDGE_BASE,
  SET_KNOWLEDGE_BASE_URL,
  ROUTE_KNOWLEDGE_BASE,
  ACTIVE_PAGE_ID
} from '../actions/actions';

export interface HubspotPage {
  id: string;
  title: string;
  urls: { app: string };
  description?: string;
  createdAt?: string;
  pages: HubspotPage[];
}

export interface KnowledgeBaseState {
  allPages: HubspotPage[];
  subPages: HubspotPage[];
  activeRoute: 'page' | 'subpage' | 'content';
  activeURL: string;
  urls: { published: string; public: string; app: string };
  history: HubspotPage[][];
  activePageIds: string[];
}

const initialState: KnowledgeBaseState = {
  activeRoute: 'page',
  allPages: [],
  subPages: [],
  activeURL: '',
  urls: { published: '', public: '', app: '' },
  history: [],
  activePageIds: []
}

export const knowledgeBaseReducer = (state = initialState, action: { type: string, payload: any }) => {

  switch (action.type) {
    case ADD_KNOWLEDGE_BASE: {
      return { ...state, allPages: action.payload.allPages, urls: action.payload.urls };
    }
    case GET_KNOWLEDGE_BASE: {
      return state;
    }
    case SET_KNOWLEDGE_BASE_SUBPAGE: {
      return { ...state, subPages: action.payload };
    }
    case SET_KNOWLEDGE_BASE_URL: {
      return { ...state, activeURL: action.payload };
    }
    case RESET_KNOWLEDGE_BASE: {
      if (action.payload.type === 'subpages') {
        return { ...state, subPages: [], activeURL: '', activePageIds: [] };
      } else {
        return { ...state, activeURL: '' };
      }
    }
    case ROUTE_KNOWLEDGE_BASE: {
      return { ...state, activeRoute: action.payload };
    }
    case ACTIVE_PAGE_ID: {
      return {
        ...state, activePageIds: action.payload.type === 'add'
          ? [...state.activePageIds, action.payload.id]
          : state.activePageIds.filter(p => p !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};


