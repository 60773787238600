import { UPDATE_CONFIGURATION } from "../actions";
import { createNewReference } from "../services/shared";

let initialState: any = {};
if ((window as any)?.parent?.BotPenguinData) {
  initialState = (window as any)?.parent?.BotPenguinData?.configuration;
} else {
  initialState = {};
}

const configurationReducer = ( state = initialState, action?: any ) => {
  if (action.type === UPDATE_CONFIGURATION) {
    return createNewReference({ ...state, ...action.payload });
  }
  return state;
};

export { configurationReducer };
