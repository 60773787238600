import { NEW_MESSAGE, UPDATE_MESSAGES, UPDATE_SINGLE_MESSAGES, LOAD_MESSAGE, SYSTEM_MESSAGE, INITIALIZE_LANDING_PAGE, LOAD_MESSAGES, RESET_MESSAGES } from './actions';

export const newMessage = (payload: any) => ({
  type: NEW_MESSAGE,
  payload
});

export const updateMessage = (payload: any) => ({
  type: UPDATE_MESSAGES,
  payload
});

export const loadMessages = (payload: any) => ({
  type: LOAD_MESSAGE,
  payload
});

export const restoreMessages = (payload: any) => ({
  type: LOAD_MESSAGES,
  payload
});

export const updateSingleMessage = (payload: any) => ({
  type: UPDATE_SINGLE_MESSAGES,
  payload
});

export const resetMessages = () => ({
  type: RESET_MESSAGES
});


export interface ISystemMessage {
  method: 'no-agent-found' | 'initiating-live-chat' | 'chat-request-accepted' | 'chat-restarted';
  title: string;
  titleColor?: string;
  subTitle: string;
  agentName?: string;
}

export const addSystemMessage = (payload: ISystemMessage) => ({
  type: SYSTEM_MESSAGE,
  payload
});

export const initializeMessages = (payload: any[]) => ({
  type: INITIALIZE_LANDING_PAGE,
  payload
});

