import { LOAD_APPOINTMENT_META, UPDATE_APPOINTMENT_META } from './actions';

export const updateAppointmentMeta = (payload: any) => ({
  type: UPDATE_APPOINTMENT_META,
  payload
});

export const loadAppointmentMeta = (payload: any) => ({
  type: LOAD_APPOINTMENT_META,
  payload
});
