import { httpRequest } from '../../../services/network';
import { encryptPayload } from '../../../services/saveResponse';
import { STORE } from '../../../store';
import { getAnsweredMessagesAndDateTime, areAllQuestionTypesPresent, getUserTimeZone } from '../Common';
import { Appointment } from '../../../interfaces/appointment';


export const getAcuityAppointmentType = async () => new Promise(async (resolve, reject) => {
  try {
    const { environment, flows } = STORE.getState();
    const questionArray = flows[0].questions;
    const requiredQue: boolean = areAllQuestionTypesPresent(questionArray, ['email', 'name', 'phone']);

    if (!requiredQue) {
      return resolve({ status: false, message: 'Email, name and phone number are required to create an appointment' });
    }
    const appointmentTypes: any = await httpRequest('POST', 'acuity-booking-types', {
      binary: encryptPayload({
        botId: environment._id,
        _user: environment._user
      })
    });
    resolve({ status: true, response: appointmentTypes.data || appointmentTypes.response || appointmentTypes });
  } catch (error) {
    resolve({ status: false, error });
  }
});

export const getAcuityDates = async (appointmentObj: Appointment | any, appointmentId: string) => new Promise(async (resolve, reject) => {
  try {
    const { environment } = STORE.getState();
    const response: any = await httpRequest('POST', 'acuity-dates-available', {
      binary: encryptPayload({
        botId: environment._id,
        _user: environment._user,
        timezone: getUserTimeZone(),
        appointmentId,
        futureDays: appointmentObj?.futureDays
      })
    });
    resolve({ status: true, response: response.data || response.response || response });
  } catch (error) {
    resolve({ status: false, error });
  }
});

export const getAcuitySlots = async (answer: string, appointmentId: string) => new Promise(async (resolve, reject) => {
  try {
    let { environment, appointmentMeta } = STORE.getState();
    const appointment = (appointmentMeta.acuityTypes || []).find((appointment: any) => appointment.id === appointmentId);
    const response: any = await httpRequest('POST', 'acuity-timeslot-available', {
      binary: encryptPayload({
        botId: environment._id,
        _user: environment._user,
        appointmentId,
        duration: appointment.duration,
        date: answer
      })
    });
    resolve({ status: true, response: response.data || response.response || response });
  } catch (error) {
    resolve({ status: false, error });
  }
});

export const createAcuityBooking = async (answer: string) => new Promise(async (resolve, reject) => {
  try {
    let { environment, messages, appointmentMeta } = STORE.getState();
    const allSlots = appointmentMeta.slots;
    const { selectedDateTime, answeredMessages } = getAnsweredMessagesAndDateTime(allSlots, messages, answer);

    if (selectedDateTime === undefined) {
      throw new Error('No valid date and time slot found.')
    }

    const appointmentId = appointmentMeta.selectedValues.acuityId;
    const appointment = (appointmentMeta.acuityTypes || []).find((appointment: any) => appointment.id === appointmentId);
    const response: any = await httpRequest('POST', 'acuity-book-appointment', {
      binary: encryptPayload({
        botId: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        dateTime: selectedDateTime.start,
        messages: answeredMessages,
        appointment: appointment,
      })
    });
    resolve({ status: true, response: response.data || response.response || response });
  } catch (error) {
    resolve({ status: false, error });
  }
});
