import { LOAD_ENVIRONMENT, UPDATE_ENVIRONMENT } from './actions';

export const updateEnvironment = (payload: any) => ({
  type: UPDATE_ENVIRONMENT,
  payload
});

export const loadEnvironment = (payload: any) => ({
  type: LOAD_ENVIRONMENT,
  payload
});

