import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { updateEnvironment } from '../../../actions/environment.action';
import { getAttributesList } from '../../../utilities/Response';
import { isValidObjectId } from '../../../services/bot';

const NotFound = lazy(() => import('../NotFound'));
const LauncherIconHandler = lazy(() => import('../LauncherIconHandler/'));
const LandingPageHandler = lazy(() => import('../LandingPageHandler/'));

export default function ViewHandler() {

	const [viewType, setViewType] = useState<'' | 'launcher-icon' | 'landing-page' | '404'>('');
	const location = useLocation();
	const dispatch = useDispatch();

	const getAttribute = useCallback((userId: string) => {
		getAttributesList(userId)
			.then((attributesList) => {

				dispatch(updateEnvironment({ customAttributesList: attributesList }));

			}).catch((error) => {
				console.log(`error in getAttributesList ${error}`);
			})
	}, [dispatch]);

	const getViewType = useCallback(() => {
		try {
			const pathSegments = location.pathname.split('/');
			const userId = pathSegments[2] || ''; // always picks userId (index 2)
			const botId = pathSegments[1] || ''; // always picks botsId (index 1)
			const isLandingPage = (isValidObjectId(userId) && isValidObjectId(botId));

			if ((window as any).parent.BotPenguinData && !isLandingPage) {
				getAttribute((window as any).parent.BotPenguinData._user);
				dispatch(updateEnvironment({ viewType: 'launcher-icon' }));
				return setViewType('launcher-icon');
			}

			if (isLandingPage) {
				dispatch(updateEnvironment({ viewType: 'landing-page' }));
				getAttribute(userId);
				return setViewType('landing-page');
			}

			setViewType('404');
			dispatch(updateEnvironment({ viewType: '404' }));
		} catch (error) {
			console.log(error);
		}
	}, [dispatch, location.pathname, getAttribute]);

	useEffect(() => {
		getViewType();
	}, [getViewType]);

	return (
		<>
			{
				viewType === 'launcher-icon' &&
				<div>
					<Suspense>
						<LauncherIconHandler />
					</Suspense>
				</div>
			}
			{
				viewType === 'landing-page' && (
					<div>
						<Suspense>
							<LandingPageHandler />
						</Suspense >
					</div >
				)
			}
			{
				viewType === '404' &&
				<Suspense>
					<NotFound />
				</Suspense>
			}
		</>
	)
}
