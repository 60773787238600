
import { SET_FOOTER_TAB } from '../actions/actions';

type FooterState = 'Chat' | 'Knowledge Base' | 'Tickets';

export const footerReducer = (state: FooterState = 'Chat', action: { type: string, payload: any }) => {

  switch (action.type) {
    case SET_FOOTER_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};


