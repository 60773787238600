import { UPDATE_BOT_HANDLER } from '../actions/actions';

const initialState: 'BOT' | 'AGENT' = 'BOT';

const botHandlerReducer = (state = initialState, action: any) => {
  if (action.type === UPDATE_BOT_HANDLER) {
    return action.payload || state;
  }
  return state;
};

export { botHandlerReducer };
