import { getAcuityAppointmentType, getAcuityDates } from "../Acuity";
import { getGcAvailableDatesAndSlots } from "../Google-Calendar";
import { getGhlAvailableDatesAndSlots } from "../GoHighLevel";
import { getSimplyBookDates, formatDateString } from "../SimplyBook";
import { STORE } from "../../../store";

//General error message
const errorResponse = {
  success: false,
  message: '',
  availableDates: [],
  availableSlots: [],
  acuityId: '' // Only case when we need to send acuityId alongside dates.
};

//Error message in case of Acuity Sceduling.
const errorResponseTypes = {
  success: false,
  message: '',
  availableTypes: []
};

export const getAppointmentTypes = async (): Promise<{ success: boolean, availableTypes: Array<any>, message?: string }> => {
  try {
    /**
     * As of now we are only rendering appointment types for acuity scheduling.
     */
    const types: any = await getAcuityAppointmentType();

    if (!types?.status || !types.response?.length) {
      errorResponseTypes.message = types.error?.response?.data?.message || types.error?.message;
      return errorResponseTypes;
    }
    return {
      success: true,
      availableTypes: types.response
    }
  } catch (error: any) {
    errorResponseTypes.message = error?.message || 'Failed to fetch acuity types';
    return errorResponseTypes;
  }
}

export const getDatesAndSlots = async (appointmentHandlerDetail: any, answer?: string): Promise<{ success: boolean, availableDates: Array<any>, availableSlots: Array<any>, acuityId?: string, message?: string }> => {
  try {
    const handlerType = appointmentHandlerDetail.appointmentHandler;
    let datesAndSlots: any;
    let availableDates: Array<any> = [];
    let availableSlots: Array<any> = [];
    let acuityObject: any
    /**
     * For Simply-book, Ghl and Google calendar we don't require the answer as for these handlers 
     * we will render the dates at first and not the appointment types.
     */

    switch (handlerType) {
      case 'simply-book':
        const maxRange = appointmentHandlerDetail.maxRange;
        datesAndSlots = await getSimplyBookDates(maxRange);
        if (datesAndSlots.status) {
          if (!datesAndSlots.response?.length) return errorResponse;
          const filteredDates = datesAndSlots.response.filter((dateInfo: any) => !dateInfo.is_day_off);
          const simplyAllListedDays = filteredDates.map((dateInfo: any) => formatDateString(dateInfo.date));
          availableDates = simplyAllListedDays.map(formatDateString);
        }
        break;

      /**
      * For Google and Ghl we will get the slots too alongside dates from same api call.
      */

      case 'google':
        const appointmentDetailsGoogle = appointmentHandlerDetail.appointment;
        datesAndSlots = await getGcAvailableDatesAndSlots(appointmentDetailsGoogle);
        if (datesAndSlots.status) {
          availableSlots = [datesAndSlots.response.slots];
          availableDates = datesAndSlots.response.dates;
        }
        break;

      case 'go-high-level':
        const appointmentDetailsGhl = appointmentHandlerDetail.appointment;
        datesAndSlots = await getGhlAvailableDatesAndSlots(appointmentDetailsGhl);
        if (datesAndSlots.status) {
          availableSlots = [datesAndSlots.response.slots];
          availableDates = datesAndSlots.response.dates;
        }
        break;

      /**
       * The answer will only be used in case of acuity as for acuity before rendering dates we have to render appoinment types,
       * and based on selected appointment type we need to render dates for that , answer will contain the selected appointment type,
       * so before this api , appointment types must have been rendered and selected to by user.
       */

      case 'acuity':
        const state: any = STORE.getState();
        const { acuityTypes } = state.appointmentMeta;
        const appointmentDetailsAcuity = appointmentHandlerDetail.appointment;
        acuityObject = acuityTypes.find((obj: any) => obj.name === answer);
        datesAndSlots = await getAcuityDates(appointmentDetailsAcuity, acuityObject.id);
        if (datesAndSlots.status) {
          availableDates = datesAndSlots.response;
        }
        break;

      default:
        throw new Error('Invalid Handler');
    }

    if (!datesAndSlots.status || !availableDates.length) {
      errorResponse.message = datesAndSlots.error.response?.data?.message || datesAndSlots.error.message;
      return errorResponse;
    }

    return {
      success: true,
      availableDates,
      availableSlots,
      // The below key is only required for acuity api's.
      ...(handlerType === 'acuity' && { acuityId: acuityObject.id }),
      message: 'Dates fetched successfully!'
    };

  } catch (error: any) {
    errorResponse.message = error.message || 'Failed to fetch dates'
    return errorResponse;
  }
}
