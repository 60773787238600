
import { RATING_SCREEN } from '../actions/actions';

export const ratingReducer = (state = false, action: { type: string, payload: boolean }) => {
  switch (action.type) {
    case RATING_SCREEN: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
