
const getSound = (type: string) => {
  let endPath = '';
  switch (type) {
    case 'long-pop':
      endPath = 'long-pop.wav';
      break;
    case 'bottle':
      endPath = 'bottle.mp3';
      break;
    case 'mouth-pops':
      endPath = 'multi-pop.mp3';
      break;
    case 'selection-sounds':
      endPath = 'selection-sounds.mp3';
      break;
    case 'tap-notification':
      endPath = 'tap-notification.mp3';
      break;
    case 'ui-popup':
      endPath = 'ui-pop-up.mp3';
      break;
    case 'achievement-bell':
      endPath = 'achievement-bell.wav';
      break;
    case 'beep':
      endPath = 'beep.mp3';
      break;
    case 'computer-wav':
      endPath = 'computer-wav.mp3';
      break;
    case 'correct-answer-tone':
      endPath = 'correct-answer-tone.wav';
      break;
    case 'multi-pop':
      endPath = 'multi-pop.mp3';
      break;
    case 'software-interface-start':
      endPath = 'software-interface-start.wav';
      break;
    default:
      endPath = '';
      break;
  }
  return process.env.REACT_APP_CDN + `/sound/${endPath}`;
}

export const botAppearanceSound = (notificationSetting: INotificationSetting) => {
  if (!notificationSetting || notificationSetting.onBotAppearance === 'none') {
    return;
  }
  playSound(notificationSetting.onBotAppearance);
}

export const launcherIconAppearanceSound = (notificationSetting: INotificationSetting) => {
  const launcherIconSound = notificationSetting.onLauncherIconAppearance;

  if (!launcherIconSound || launcherIconSound === 'none') {
    return;
  }

  playSound(launcherIconSound);
}

export const newMessageSound = (notificationSetting: INotificationSetting) => {

  if (!notificationSetting || notificationSetting.onChatWindow === 'none' || document.visibilityState !== 'hidden') {
    return;
  }
  playSound(notificationSetting.onChatWindow);
}

const playSound = (soundType: string) => {
  let sound = getSound(soundType);

  const audio = new Audio(sound);
  audio.play()
    .catch((err) => console.log(`Error while play sound: ${err}`));

}

interface INotificationSetting {
  onChatWindow: string;
  onBotAppearance: string;
  onMobile: string;
  onLauncherIconAppearance: string;
}
