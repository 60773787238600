import { reducer } from '../reducers';
import { configureStore, createImmutableStateInvariantMiddleware } from '@reduxjs/toolkit';

const STORE = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createImmutableStateInvariantMiddleware())
});

STORE.subscribe(() => {
  const { environment } = STORE.getState();

  if (environment && !environment._flow) {
    return false;
  }

  if ((window as any).parent.BotPenguinData && !(window as any).parent.BotPenguinData?.preview) {
    sessionStorage.setItem(`BotPenguin-${(window as any).parent.BotPenguinData?._id}`, JSON.stringify(STORE.getState()));
    localStorage.setItem(`BotPenguin-${(window as any).parent.BotPenguinData?._id}`, JSON.stringify(STORE.getState()));
  }

  if (localStorage.getItem('BotPenguin_Landing_Page')) {
    sessionStorage.setItem(`BotPenguin-${JSON.parse(localStorage.getItem('BotPenguin_Landing_Page') || '')?._id}`, JSON.stringify(STORE.getState()));
    localStorage.setItem(`BotPenguin-${JSON.parse(localStorage.getItem('BotPenguin_Landing_Page') || '')?._id}`, JSON.stringify(STORE.getState()));
  }
});

export { STORE };
