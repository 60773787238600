import { httpRequest } from '../../../services/network';
import { encryptPayload } from '../../../services/saveResponse';
import { STORE } from '../../../store';
import { getAnsweredMessagesAndDateTime, isAnyTypeFoundInQuestions, getUserTimeZone } from '../Common';
import { Appointment } from '../../../interfaces/appointment';

/**
* Date: 27/Jul/2024
* Author: Rajan Grover
* Summary:Function retrieves available dates and slots from our gohighlevel/dates endpoint.
* @param {Object} appointmentObj - Appointment object containing details like availableDays, startTime, endTime, interval, etc.
* @returns {Promise<Object>} Promise that resolves to an object with status and response data and data includes slots and dates.
*/
export const getGhlAvailableDatesAndSlots = async (appointmentObj: Appointment | any) => new Promise(async (resolve, reject) => {
	try {
		const { environment, flows } = STORE.getState();
		const questionArray = flows[0].questions;
		const ifEmailOrPhoneQueExist = isAnyTypeFoundInQuestions(questionArray, ['email', 'phone']);
		if (!ifEmailOrPhoneQueExist) {
			return resolve({ status: false, message: 'Email or Phone number are required to create an appointment' });
		}
		const response: any = await httpRequest('POST', 'gohighlevel/dates', {
			binary: encryptPayload({
				_bot: environment._id,
				_user: environment._user,
				timezone: getUserTimeZone(),
				futureDays: appointmentObj?.futureDays
			})
		});
		resolve({ status: true, response: response.data || response.response || response });
	} catch (error) {
		resolve({ status: false, error });
	}
});

/**
* Date: 27/Jul/2024
* Author: Rajan Grover
* Summary:The function makes an appointment on go high level calendar, based on the provided answer.
 * @param {string} answer - The selected time slot in the format '9:00-9:30'.
 * @returns {Promise<Object>} Promise that resolves to an object with status and response data.
*/

export const makeGhlAppointment = async (answer: string) => new Promise(async (resolve, reject) => {
	try {
		let { environment, messages, appointmentMeta } = STORE.getState();
		const allSlots = appointmentMeta.slots;
		const { selectedDateTime, answeredMessages } = getAnsweredMessagesAndDateTime(allSlots, messages, answer);

		if (selectedDateTime === undefined) {
			throw new Error('No valid date and time slot found.')
		}
		const response: any = await httpRequest('POST', 'gohighlevel/book/slot', {
			binary: encryptPayload({
				_bot: environment._id,
				_user: environment._user,
				uuid: environment.uuid,
				startDate: selectedDateTime.start,
				endDate: selectedDateTime.end,
				messages: answeredMessages,
			})
		});
		resolve({ status: true, response: response.data || response.response || response });
	} catch (error) {
		resolve({ status: false, error });
	}
});

