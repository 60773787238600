import { httpRequest } from '../../../services/network';
import { encryptPayload } from '../../../services/saveResponse';
import { STORE } from '../../../store';


export const formatDateString = (dateStr: string) => {
  const date = new Date(dateStr);
  return date.toISOString().split('T')[0];
}

export const getSimplyBookDates = async (maxRange = 10) => new Promise(async (resolve, reject) => {
  try {
    const { environment, messages } = STORE.getState();
    const currentDate: any = new Date();
    const futureDates: any = new Date(new Date().setDate(new Date().getDate() + (maxRange || 10)))
    const response: any = await httpRequest('POST', 'simplybook-dates', {
      binary: encryptPayload({
        _bot: environment._id,
        _user: environment._user,
        formattedToday: formatDateString(currentDate),
        formattedFutureDate: formatDateString(futureDates),
        messages
      })
    });

    resolve({ status: true, response: response.data || response.response || response });
  } catch (error) {
    resolve({ status: false, error });
  }

});

export const getSimplyBookSlots = async (date: string) => new Promise(async (resolve, reject) => {
  try {
    const { environment, messages } = STORE.getState();
    const response: any = await httpRequest('POST', 'simplybook-slots', {
      binary: encryptPayload({
        _bot: environment._id,
        _user: environment._user,
        date: date,
        messages
      })
    });

    resolve({ status: true, slots: response.data.data, duration: response.data.duration });
  } catch (error) {
    resolve({ status: false, error });
  }
});

export const makeSimplyBooking = async (startTime: string) => new Promise(async (resolve, reject) => {
  try {
    const { environment, messages } = STORE.getState();
    const response: any = await httpRequest('POST', 'simplybook-booking', {
      binary: encryptPayload({
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        startTime,
        messages
      })
    });

    resolve({ status: true, response: response.response });
  } catch (error) {
    reject({ status: false, error });
  }
});

export const formatTimeString = (time: Date) => {
  const hours = time.getHours()
    .toString()
    .padStart(2, '0');
  const minutes = time.getMinutes()
    .toString()
    .padStart(2, '0');

  return `${hours}:${minutes}`;
};
