import { LOAD_TEXT_INPUT, UPDATE_TEXT_INPUT } from './actions';

export const updateTextInput = (payload: any) => ({
  type: UPDATE_TEXT_INPUT,
  payload
});

export const loadTextInput = (payload: any) => ({
  type: LOAD_TEXT_INPUT,
  payload
});
