import { HubspotPage } from '../reducers/knowledgeBase.reducer';
import {
  ADD_KNOWLEDGE_BASE,
  GET_KNOWLEDGE_BASE,
  RESET_KNOWLEDGE_BASE,
  SET_KNOWLEDGE_BASE_SUBPAGE,
  ROUTE_KNOWLEDGE_BASE,
  SET_KNOWLEDGE_BASE_URL,

  ACTIVE_PAGE_ID
} from './actions';

export const addKnowledgeBase = (payload: { allPages: HubspotPage[], urls: { public: string, published: string } }) => ({
  type: ADD_KNOWLEDGE_BASE,
  payload
});

export const getKnowledgeBase = () => ({
  type: GET_KNOWLEDGE_BASE
});

export const setKnowledgeBaseSubPage = (payload: HubspotPage[]) => ({
  type: SET_KNOWLEDGE_BASE_SUBPAGE,
  payload
});

export const setKnowledgeBaseURL = (payload: string) => ({
  type: SET_KNOWLEDGE_BASE_URL,
  payload
});

export const resetKnowledgeBase = (payload: { type: 'subpages' | 'activeURL' }) => ({
  type: RESET_KNOWLEDGE_BASE,
  payload
});

export const routeKnowledgeBase = (payload: 'page' | 'subpage' | 'content') => ({
  type: ROUTE_KNOWLEDGE_BASE,
  payload
});

export const setActivePageIds = (payload: { id: string, type: 'add' | 'remove' }) => ({
  type: ACTIVE_PAGE_ID,
  payload
});
