
import { STORE } from "../../store";
import { findQuestions, getPersona, handleRedirectFlow } from "../Response";
import { IQuestion } from "../../interfaces/Question";
import { handleUnsureAiResponseMethod } from "../../services/ai";
import { AIScenario } from "../../interfaces/ai";
import { initializeAI } from "../../actions";


/**
 * @author Mayank
 * @date 31/01/2024
 * @description : This function is used to create bot response as per user's configuration in case of invalid ai response
 * @param activeQuestion : Current active question for creating payload
 * @param result : It contains type and the message which we have to send
 * @returns 
 */

export const getFinalAiQuestion = async (activeQuestion: IQuestion, result: any) => {
	let finalAiQuestion;
	try {
		switch (result.type) {
			//if user has configured to show error message only then we are sending error message
			case "text": {
				finalAiQuestion = [
					{
						...activeQuestion,
						label: result.message,
						type: 'AI',
						delay: 0,
						shouldFeedbackVisible: true,
						_id: undefined,
						suggestions: activeQuestion.suggestions
					}];
				break;
			}

			// if user has configured for live_chat then we are sending a button component on click of which live chat will be triggered
			case "button": {
				finalAiQuestion = [
					{
						...activeQuestion,
						type: "button",
						label: result.message.label,
						options: result.message.options,
						delay: 0,
						shouldFeedbackVisible: true,
						_id: undefined,
					}];
				break;
			}

			//if user has configured to invoke new message then we are sending configured message
			case "question": {
				const { flows } = STORE.getState();
				const questions = flows[0]?.questions;
				const index = questions.findIndex((que: IQuestion) => que.id === result.message.next.target);
				finalAiQuestion = [{
					...activeQuestion,
					label: result.message.label,
					type: 'question',
					next: {
						type: 'question',
						target: result.message.next.target
					}
				}, ...await findQuestions(questions, index)];
				break;
			}

			case "end-chat": {
				finalAiQuestion = [
					{
						...activeQuestion,
						label: result.message,
						delay: 0,
						type: 'question',
						shouldFeedbackVisible: true,
						_id: undefined,
						next: {
							type: 'question',
							target: "end"
						}
					}]
				break;
			}

			case 'userdefined_intent':
				if (result.trigger === 'message') {
					finalAiQuestion = [
						{
							...activeQuestion,
							label: result.message || '',
							delay: 0,
							type: 'AI',
							shouldFeedbackVisible: true,
							_id: undefined,
							next: {
								type: 'question',
								target: ""
							}
						}
					];
				}

				if (result.trigger === 'flow') {
					const flow = await handleRedirectFlow({
						...activeQuestion,
						type: 'redirect',
						next: {
							type: 'redirect',
							target: result._flow
						}
					});
					finalAiQuestion = [...await findQuestions(flow.questions, 0)];
				}
				break;

			default: {
				return finalAiQuestion;
			}
		}
		return finalAiQuestion;
	} catch {
		return [];
	}
}

/**
 * This function will check user's configuration for invalid ai response and process accordingly
 */
export const handleUnsureAiResponse = async (aiResponse: any) => {
	try {
		const { ai } = STORE.getState();

		const { persona } = ai;
		let data = persona;
		let finalMessage;

		if (!persona || (Object.keys(persona).length <= 1)) {
			data = await getPersona(persona._id);
			if (Object.keys(data).length) {
				STORE.dispatch(initializeAI({
					...ai,
					persona: data
				}));
			}
		}

		const aiConfiguredMessages = {
			noAnswer: data.scenarios[0]?.message,
			unusualChatHandling: {
				unableToAnswer: {
					showErrorMessage: true,
					showLiveChatButton: {
						enabled: data.scenarios[0]?.showLiveChatButton?.enabled,
						text: data.scenarios[0]?.showLiveChatButton?.text
					},
					invokeNewFlow: {
						enabled: data.scenarios[0]?.trigger === 'flow',
						flowId: data.scenarios[0]?._flow || ''
					}
				}
			}
		};

		finalMessage = handleUnsureAiResponseMethod(aiResponse, aiConfiguredMessages);

		return finalMessage;
	} catch (error) {
		return { type: 'text', message: '' };
	}

};

/**
 * This function will check the issue with ai response and process accordingly
 */
export const handleInvalidAiResponse = async (response: any) => {

	let result;
	try {
		switch (response.case) {
			case 'unsure': {
				result = await handleUnsureAiResponse(response);
				break;
			}
			case 'inappropriate_lang':
			case 'userdefined_intent': {
				result = handlePredefinedIntent(response.scenario);
				break;
			}
			default: {
				return result;
			}
		}
		return result;
	} catch {
		return result
	}
}

const handlePredefinedIntent = (scenario: AIScenario) => {
	return { type: 'userdefined_intent', trigger: scenario.trigger, message: scenario.message || '', _flow: scenario._flow };
};

